<template>
  <d-container fluid>
    <pageTitle title="Voertuig" :back="true"/>
    <d-row>
      <vehicleHeader v-if="vehicle !== null" :make="vehicle.make.name" :model="vehicle.model.name"/>
      <vehicleInformationCard v-if="vehicle !== null" :vehicle="vehicle"/>
      <expectedDamageReportCard :vehicle="{id: 1}"/>
      <vehicleUspCard :vehicle="{id: 4}"/>
      <vehicleOptionsCard :vehicleID="$route.params.vehicle_id"/>
    </d-row>
  </d-container>
</template>

<script>
export default {
  name: 'purchaseList',
  created() {
    this.getVehicle()
  },
  title: 'TVI (Technische Voertuig Inname)',
  components: {
    selectVehicleModel: () => import('@/components/forms/selectVehicleModel'),
    expectedDamageReportCard: () => import('../components/expectedDamageReportCard'),
    vehicleInformationCard: () => import('../components/vehicleInformationCard'),
    vehicleOptionsCard: () => import('../components/vehicleOptionsCard'),
    vehicleUspCard: () => import('../components/vehicleUspCard'),
    vehicleHeader: () => import('../components/vehicleHeader')
  },
  data() {
    return {
      vehicle: null,
      vehicleOptions: null,
      vehicleOptionCategories: null
    }
  },
  methods: {
    async getVehicle() {
      let {data} = await this.$api.vehicle.getByUUID(this.$route.params.vehicle_id)
      this.vehicle = data

      console.log(data)
    }
  }
}
</script>
